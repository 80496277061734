








































































import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

const AUTH_TOKEN = "apollo-token";

@Component({
  components: {},
  props: ["params", "url", "headers"],
  mounted() {
    (this as any).load();
  },
  computed: {},
  data: () => ({
    search: "",
    items: [],
    expanded: [],
    singleExpand: false,
    moment,
  }),
  methods: {
    async load() {
      console.log("load data");
      this.$data.items = Object.freeze(
        (
          await axios.post(
            this.$props.url,
            { ...this.$props.params },
            {
              headers: {
                authorization: `Bearer ${
                  JSON.parse(localStorage.getItem(AUTH_TOKEN) || "")
                    .access_token
                }`,
              },
            }
          )
        ).data
      );
      console.log("load data", this.$data.items);
    },
  },
})
export default class Breweries extends Vue {}
