var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.telemetry.some(function (x) { return x.unit.abbreviation == 'SG'; }) &&
    _vm.telemetry.some(function (x) { return x.unit.abbreviation == 'AA'; }) &&
    _vm.telemetry.some(function (x) { return x.unit.abbreviation == 'FA'; }) &&
    _vm.telemetry.some(function (x) { return x.unit.abbreviation == 'mbar'; })
  )?_c('apexchart',{key:_vm.id,attrs:{"type":"line","height":"400","options":{
    tooltip: {
      enabled: true,
      shared: true,
      custom: function (ref) {
        var series = ref.series;
        var seriesIndex = ref.seriesIndex;
        var dataPointIndex = ref.dataPointIndex;
        var w = ref.w;

        var hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
        var hoverIndexes = w.globals.seriesX.map(function (seriesX) {
          return seriesX.findIndex(function (xData) { return xData === hoverXaxis; });
        });

        var hoverList = '';
        hoverIndexes.forEach(function (hoverIndex, seriesEachIndex) {
          if (hoverIndex >= 0) {
            hoverList += "<span>" + (w.globals.seriesNames[seriesEachIndex]) + ": " + (series[seriesEachIndex][hoverIndex]) + "</span><br />";
          }
        });
        return ("<div class='card'>\n      <div class='card-header p-1'>" + hoverXaxis + "</div>\n      <div class='card-body p-1'>\n        " + hoverList + "\n      </div>\n    </div>");
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    chart: {
      id: 'vuechart-example',
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
        type: 'x',
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'datetime',
    },
    stroke: {
      width: [3, 3, 2],
      curve: 'smooth',
    },
    annotations: {
      xaxis: [].concat( _vm.meta
          .map(function (x) { return x.data.map(function (y) { return (Object.assign({}, y, {ts: x.ts})); }); })
          .flat()
          .filter(function (y) { return ['INTERVENTIONS', 'COMMENT'].indexOf(y.key) > -1; })
          .map(function (x) { return ({
            x: new Date(x.ts).getTime(),
            borderColor: '#dadada',
            label: {
              style: {
                color: '#000',
                background: '#f7f7c7',
              },
              text: x.value,
            },
          }); }) ),
    },
    yaxis: [
      {
        color: '#f00',
        axisBorder: {
          show: true,
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        min: 1.0,
        max:
          Math.max.apply(
            Math, _vm.telemetry
              .find(function (x) { return x.unit.abbreviation == 'SG'; })
              .values.map(function (x) { return x.value; })
          ) + 0.004,
        decimalsInFloat: 3,
        title: 'SG',
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      {
        min: 0,
        max: 100,
        decimalsInFloat: 1,
        title: 'AA',
        opposite: true,
        axisBorder: {
          show: true,
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      {
        decimalsInFloat: 1,
        axisBorder: {
          show: true,
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        opposite: true,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      {
        decimalsInFloat: 1,
        axisBorder: {
          show: true,
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        opposite: true,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      } ],
  },"series":[
    {
      name: 'SG',
      data: _vm.telemetry
        .find(function (x) { return x.unit.abbreviation == 'SG'; })
        .values.map(function (x) { return ({ x: new Date(x.ts).getTime(), y: x.value }); }),
    },
    {
      name: 'AA',
      data: _vm.telemetry
        .find(function (x) { return x.unit.abbreviation == 'AA'; })
        .values.map(function (x) { return ({ x: new Date(x.ts).getTime(), y: x.value }); }),
    },
    {
      name: 'FA',
      data: _vm.telemetry
        .find(function (x) { return x.unit.abbreviation == 'FA'; })
        .values.map(function (x) { return ({ x: new Date(x.ts).getTime(), y: x.value }); }),
    },
    {
      name: 'P',
      data: _vm.telemetry
        .find(function (x) { return x.unit.abbreviation == 'mbar'; })
        .values.map(function (x) { return ({ x: new Date(x.ts).getTime(), y: x.value }); }),
    } ]}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }