
















































































































































































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {},
  props: ["telemetry", "meta"],
  computed: {
    id() {
      window.console.log(
        "asdasd",
        (this as any).telemetry.find(
          (x: any) => x.unit.abbreviation == "INTERVENTIONS"
        )
      );
      return this.$route.params.id;
    },
  },
  data: () => ({
    moment,
  }),
  methods: {},
})
export default class Breweries extends Vue {}
